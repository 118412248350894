var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getMenuList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    codeGroupCd: "DEVICE_CD",
                    type: "none",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "deviceTypeCd",
                    label: "디바이스구분",
                  },
                  on: { input: _vm.getMenuList },
                  model: {
                    value: _vm.searchParam.deviceTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "deviceTypeCd", $$v)
                    },
                    expression: "searchParam.deviceTypeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.useFlagItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "useFlag",
                    label: "LBLUSEFLAG",
                  },
                  on: { input: _vm.getMenuList },
                  model: {
                    value: _vm.searchParam.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "useFlag", $$v)
                    },
                    expression: "searchParam.useFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c(
              "c-tree-table",
              {
                attrs: {
                  title: "메뉴목록",
                  parentProperty: "upMenuId",
                  customID: "sysMenuId",
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                  columnSetting: false,
                  isFullScreen: false,
                  usePaging: false,
                  expandAll: true,
                  hideBottom: true,
                },
                on: { rowClick: _vm.rowClick },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "LBLSEARCH", icon: "search" },
                          on: { btnClicked: _vm.getMenuList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "메뉴정보" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable && _vm.isManager
                              ? _c("c-btn", {
                                  attrs: { label: "LBLREG", icon: "add" },
                                  on: { btnClicked: _vm.addMenu },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.deleteable && _vm.isManager
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.deleteUrl,
                                    isSubmit: true,
                                    param: _vm.menu,
                                    mappingType: "DELETE",
                                    label: "LBLREMOVE",
                                    icon: "remove",
                                  },
                                  on: {
                                    beforeAction: _vm.deleteData,
                                    btnCallback: _vm.deleteCallback,
                                  },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.saveable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.menu,
                                    mappingType: _vm.saveType,
                                    label: "LBLSAVE",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveMenu,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: "",
                              editable: _vm.editable && _vm.dataeditable,
                              label: "메뉴명",
                              name: "menuNm",
                            },
                            model: {
                              value: _vm.menu.menuNm,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "menuNm", $$v)
                              },
                              expression: "menu.menuNm",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: false,
                              codeGroupCd: "DEVICE_CD",
                              type: "edit",
                              required: true,
                              itemText: "codeName",
                              itemValue: "code",
                              name: "deviceTypeCd",
                              label: "디바이스구분",
                            },
                            model: {
                              value: _vm.menu.deviceTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "deviceTypeCd", $$v)
                              },
                              expression: "menu.deviceTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-text", {
                            attrs: {
                              disabled: true,
                              editable: _vm.editable && _vm.dataeditable,
                              label: "메뉴ID",
                              name: "sysMenuId",
                            },
                            model: {
                              value: _vm.menu.sysMenuId,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "sysMenuId", $$v)
                              },
                              expression: "menu.sysMenuId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              afterIcon: _vm.editable
                                ? [
                                    {
                                      name: "search",
                                      click: true,
                                      callbackName: "selectUpMenu",
                                      color: "light-blue",
                                    },
                                    {
                                      name: "close",
                                      click: true,
                                      callbackName: "removeUpMenu",
                                      color: "red",
                                    },
                                  ]
                                : null,
                              disabled: true,
                              editable: _vm.editable && _vm.dataeditable,
                              label: "상위 메뉴",
                              name: "upMenuNm",
                            },
                            on: {
                              selectUpMenu: () => {
                                _vm.isMenuOpen = true
                              },
                              removeUpMenu: _vm.removeUpMenu,
                            },
                            model: {
                              value: _vm.menu.upMenuNm,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "upMenuNm", $$v)
                              },
                              expression: "menu.upMenuNm",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              disabled: true,
                              editable: _vm.editable && _vm.dataeditable,
                              label: "메뉴레벨",
                              name: "menuLvl",
                            },
                            model: {
                              value: _vm.menu.menuLvl,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "menuLvl", $$v)
                              },
                              expression: "menu.menuLvl",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                _vm.dataeditable &&
                                _vm.isManager,
                              label: "메뉴 URL",
                              name: "url",
                            },
                            model: {
                              value: _vm.menu.url,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "url", $$v)
                              },
                              expression: "menu.url",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              label: "메뉴 아이콘",
                              name: "menuIcon",
                            },
                            model: {
                              value: _vm.menu.menuIcon,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "menuIcon", $$v)
                              },
                              expression: "menu.menuIcon",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: "",
                              editable: _vm.editable && _vm.dataeditable,
                              type: "number",
                              label: "정렬 순서",
                              name: "sortOrder",
                            },
                            model: {
                              value: _vm.menu.sortOrder,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "sortOrder", $$v)
                              },
                              expression: "menu.sortOrder",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.searchParam.deviceTypeCd == "P"
                        ? _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("c-checkbox", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  isFlag: true,
                                  label: "대시보드 여부",
                                  name: "dashboardFlag",
                                },
                                model: {
                                  value: _vm.menu.dashboardFlag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.menu, "dashboardFlag", $$v)
                                  },
                                  expression: "menu.dashboardFlag",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              isFlag: true,
                              label: "LBLUSEFLAG",
                              name: "useFlag",
                            },
                            model: {
                              value: _vm.menu.useFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.menu, "useFlag", $$v)
                              },
                              expression: "menu.useFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.searchParam.deviceTypeCd == "P"
                        ? _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("c-checkbox", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  isFlag: true,
                                  label: "매뉴얼 사용여부",
                                  name: "manualFlag",
                                },
                                model: {
                                  value: _vm.menu.manualFlag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.menu, "manualFlag", $$v)
                                  },
                                  expression: "menu.manualFlag",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.searchParam.deviceTypeCd == "P"
                        ? _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _vm.editable && _vm.menu.manualFlag == "Y"
                                ? _c("c-btn", {
                                    attrs: {
                                      editable:
                                        _vm.editable && _vm.dataeditable,
                                      label: "매뉴얼 관리",
                                      color: "red",
                                      icon: "quiz",
                                    },
                                    on: {
                                      btnClicked: function ($event) {
                                        return _vm.addManual(_vm.menu)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _vm.isManager
                            ? _c("c-checkbox", {
                                attrs: {
                                  editable: _vm.editable && _vm.dataeditable,
                                  isFlag: true,
                                  label: "시스템전용메뉴",
                                  name: "systemFlag",
                                },
                                model: {
                                  value: _vm.menu.systemFlag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.menu, "systemFlag", $$v)
                                  },
                                  expression: "menu.systemFlag",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "q-dialog",
        {
          ref: "menuDialog",
          attrs: { persistent: "" },
          model: {
            value: _vm.isMenuOpen,
            callback: function ($$v) {
              _vm.isMenuOpen = $$v
            },
            expression: "isMenuOpen",
          },
        },
        [
          _c(
            "q-card",
            { staticClass: "menu-card" },
            [
              _c(
                "q-card-section",
                {
                  staticClass:
                    "row items-center q-pb-none px-2 py-1 bg-orange-custom text-white",
                },
                [
                  _c("div", { staticClass: "text-h6" }, [_vm._v("메뉴")]),
                  _c("q-space"),
                  _c("q-btn", {
                    attrs: { icon: "done", flat: "", round: "", dense: "" },
                    on: { click: _vm.selectMenu },
                  }),
                  _c("q-btn", {
                    directives: [
                      { name: "close-popup", rawName: "v-close-popup" },
                    ],
                    attrs: { icon: "close", flat: "", round: "", dense: "" },
                  }),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c(
                        "q-item-label",
                        { attrs: { caption: "" } },
                        [
                          _c(
                            "q-chip",
                            {
                              attrs: {
                                outline: "",
                                square: "",
                                color: "primary",
                                "text-color": "white",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.menuNm) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("q-separator"),
              _c(
                "q-card-section",
                { staticClass: "menu-content p-2" },
                [
                  _c("q-tree", {
                    ref: "menuTree",
                    attrs: {
                      nodes: _vm.menuTree,
                      "node-key": "sysMenuId",
                      "label-key": "menuNm",
                      "children-key": "children",
                      "no-nodes-label": "메뉴가 없습니다.",
                      "no-results-label": "",
                      "default-expand-all": false,
                      "selected-color": "primary",
                      selected: _vm.selectedMenu,
                    },
                    on: {
                      "update:selected": function ($event) {
                        _vm.selectedMenu = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }